import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'

import SectionContainer from '../common/section-container'

export const DecorativeContainer = styled.div`
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: ${spacing(12)};
  }
  &:before {
    background-color: ${NeutralColors.Ink};
  }
  &:after {
    background-color: ${NeutralColors.White};
    border-bottom-left-radius: ${spacing(15)};
    border-bottom-right-radius: ${spacing(15)};
  }
`
export const StatsContainer = styled(SectionContainer)`
  padding-bottom: 96px;
  row-gap: 48px;
`

export const Stats = styled.div<{ position: number }>`
  display: flex;
  gap: ${spacing(4)};
  grid-column: 1 / -1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  ${mediaQueries.sm`
    flex-direction: row;
    align-items: center;
    text-align: left;
    grid-column: 2 / -2;
  `}

  ${mediaQueries.md`
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-column: ${({ position }: { position: number }) =>
      `${3 + position * 3} / span 2`};
  `}
`
