import { NAMESPACES } from 'constants/locales'

import React, { memo, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Text, {
  SIZE as TEXT_SIZE,
} from '@typeform/ginger/dist/components/typography/text/next'
import Headline, {
  SIZE as HEADLINE_SIZE,
} from '@typeform/ginger/dist/components/typography/headline/next'
import HeadlineModule, {
  HeadlineModuleLayout,
} from 'components/modules/headline-module'

import TrackingLocation, {
  TrackingLocationNames,
} from '../common/tracking-location'

import {
  DecorativeContainer,
  Stats,
  StatsContainer,
} from './why-typeform-section.styles'

const ProfilesSection = () => {
  const { t } = useTranslation(NAMESPACES.HOME)
  const statsList = useMemo(
    () => [
      {
        percentage: t('homepage.refresh.why-typeform-section.stats-0.value'),
        description: t('homepage.refresh.why-typeform-section.stats-0.label'),
      },
      {
        percentage: t('homepage.refresh.why-typeform-section.stats-1.value'),
        description: t('homepage.refresh.why-typeform-section.stats-1.label'),
      },
      {
        percentage: t('homepage.refresh.why-typeform-section.stats-2.value'),
        description: t('homepage.refresh.why-typeform-section.stats-2.label'),
      },
    ],
    [t]
  )
  return (
    <TrackingLocation name={TrackingLocationNames.Stats}>
      <HeadlineModule
        layout={HeadlineModuleLayout.Compact}
        title={t('homepage.refresh.why-typeform-section.title')}
        description={t('homepage.refresh.why-typeform-section.description')}
      />
      <DecorativeContainer data-testid='why-typeform-section'>
        <StatsContainer>
          {statsList.map(({ percentage, description }, index) => (
            <Stats key={description} position={index}>
              <Headline
                element='h3'
                largeViewportsSize={HEADLINE_SIZE.FIVE}
                mediumViewportsSize={HEADLINE_SIZE.FOUR}
                smallViewportsSize={HEADLINE_SIZE.FOUR}
                extraSmallViewportsSize={HEADLINE_SIZE.FOUR}
              >
                {percentage}
              </Headline>
              <Text
                as='span'
                mediumViewportsSize={TEXT_SIZE.M}
                smallViewportsSize={TEXT_SIZE.S}
                extraSmallViewportsSize={TEXT_SIZE.S}
              >
                {description}
              </Text>
            </Stats>
          ))}
        </StatsContainer>
      </DecorativeContainer>
    </TrackingLocation>
  )
}

ProfilesSection.displayName = 'ProfilesSection'

export default memo(ProfilesSection)
